const IconLinkedin = ({
  fill = '#ffffff',
  className,
  dataTestid = 'circle-linkedin'
}) => (
  <div>
    <svg
      className={className}
      data-testid={dataTestid}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19.5" cy="19.5" r="19" stroke={fill} />
      <path
        className={`${className}-path`}
        data-testid={`${dataTestid}-path`}
        d="M25.2143 12H13.7857C12.8 12 12 12.8 12 13.7857V25.2143C12 26.2 12.8 27 13.7857 27H25.2143C26.2 27 27 26.2 27 25.2143V13.7857C27 12.8 26.2 12 25.2143 12ZM16.6429 17.7143V24.5H14.5V17.7143H16.6429ZM14.5 15.7393C14.5 15.2393 14.9286 14.8571 15.5714 14.8571C16.2143 14.8571 16.6179 15.2393 16.6429 15.7393C16.6429 16.2393 16.2429 16.6429 15.5714 16.6429C14.9286 16.6429 14.5 16.2393 14.5 15.7393ZM24.5 24.5H22.3571C22.3571 24.5 22.3571 21.1929 22.3571 20.9286C22.3571 20.2143 22 19.5 21.1071 19.4857H21.0786C20.2143 19.4857 19.8571 20.2214 19.8571 20.9286C19.8571 21.2536 19.8571 24.5 19.8571 24.5H17.7143V17.7143H19.8571V18.6286C19.8571 18.6286 20.5464 17.7143 21.9321 17.7143C23.35 17.7143 24.5 18.6893 24.5 20.6643V24.5Z"
        fill={fill}
      />
    </svg>
  </div>
);

export default IconLinkedin;
